<template>
    <a-form-model :model="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 13 }">
        <div style="color:#b3b9c2">广告平台信息</div>
        <div class="platform-container">
            <a-form-model-item label="巨量项目">
                {{ form.adProjectName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="巨量项目ID">
                {{ form.adProjectId || '-' }}
            </a-form-model-item>
            <a-row type="flex" justify="space-between">
                <a-col :span="8" style="margin-left: 145px;">
                    <a-form-model-item label="投放内容">
                        {{ form.promotionType || '-' }}
                    </a-form-model-item>
                </a-col>
                <a-col :span="10">
                    <a-form-model-item label="竞价策略">
                        {{ form.deliverySetting.bidType || '-' }}
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-between">
                <a-col :span="8" style="margin-left: 135px;">
                    <a-form-model-item label="平台账套ID">
                        {{ form.adPlatformAccountId || '-' }}
                    </a-form-model-item>
                </a-col>
                <a-col :span="10">
                    <a-form-model-item label="推广目的">
                        {{ form.landingType || '-' }}
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-between">
                <a-col :span="8" style="margin-left: 145px;">
                    <a-form-model-item label="营销场景">
                        {{ form.marketingGoal || '-' }}
                    </a-form-model-item>
                </a-col>
                <a-col :span="10">
                    <a-form-model-item label="广告类型">
                        {{ form.adType || '-' }}
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-between">
                <a-col :span="8" style="margin-left: 145px;">
                    <a-form-model-item label="地域类型">
                        {{ form.district || '-' }}
                    </a-form-model-item>
                </a-col>
                <a-col :span="10">
                    <a-form-model-item label="地域明细">
                        {{ form.cityList || '-' }}
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-form-model-item label="广告投放位置">
                {{ form.deliveryRange.inventoryType || '-' }}
            </a-form-model-item>
            <a-form-model-item label="巨量项目创建时间">
                {{ form.projectCreateTime || '-' }}
            </a-form-model-item>
            <a-form-model-item label="巨量项目修改时间">
                {{ form.projectModifyTime || '-' }}
            </a-form-model-item>
            <a-form-model-item label="优化目标">
                {{ form.optimizeGoal.externalAction || '-' }}
            </a-form-model-item>
        </div>
        <div style="color:#b3b9c2">皮皮慧投定义信息</div>
        <a-form-model-item label=" 系统创建">
            {{ form.createSource === 1 ? '皮皮慧投运营后台' : '广告平台' }}
        </a-form-model-item>
        <a-form-model-item label="系统管控">
            {{ form.isSysControl ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="巨量项目品牌">
            {{ form.principalName || '-' }}
        </a-form-model-item>
        <a-form-model-item label="巨量项目车系">
            {{ form.carSeriesName || '-' }}
        </a-form-model-item>
        <a-form-model-item label="巨量项目场景">
            {{ form.customerSceneDesc || '-' }}
        </a-form-model-item>
        <a-form-model-item label="广告主">
            {{ form.advertiserName || '-' }}
        </a-form-model-item>
        <a-form-model-item label="当前关联的任务编码">
            {{ form.missionCode || '-' }}
        </a-form-model-item>
        <a-form-model-item label="当前关联的项目编码">
            {{ form.projectCode || '-' }}
        </a-form-model-item>
    </a-form-model>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: {}
        }
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-form-item {
    margin-bottom: 0;
}
</style>