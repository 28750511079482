// 竞价策略
export const bidType = [
    {
        value: 'CUSTOM',
        text: '稳定成本'
    },
    {
        value: 'NO_BID',
        text: '最大转化投放'
    },
    {
        value: 'UPPER_CONTROL',
        text: '控制成本上限'
    },
]

// 推广目的
export const landingType = [
    {
        value: 'APP',
        text: '应用推广'
    },
    {
        value: 'LINK',
        text: '销售线索推广'
    },
    {
        value: 'MICRO_GAME',
        text: '小程序'
    },
    {
        value: 'QUICK_APP',
        text: '快应用'
    },
    {
        value: 'SHOP',
        text: '电商店铺推广'
    },
    {
        value: 'NATIVE_ACTION',
        text: '原生互动'
    },
    {
        value: 'DPA',
        text: '商品目录'
    },
]

// 营销场景
export const marketingGoal = [
    {
        value: 'VIDEO_AND_IMAGE',
        text: '短视频/图片'
    },
    {
        value: 'LIVE',
        text: '直播'
    },
]

// 广告类型
export const adType = [
    {
        value: 'ALL',
        text: '所有广告'
    },
    {
        value: 'SEARCH',
        text: '搜索广告'
    },
]

// 投放内容
export const promotionType = [
    {
        value: 'AWEME_HOME_PAGE',
        text: '抖音主页'
    },
    {
        value: 'LANDING_PAGE_LINK',
        text: '落地页'
    },
]

//  地域类型
export const district = [
    {
        value: 'CITY',
        text: '省市'
    },
    {
        value: 'COUNTY',
        text: '区县'
    },
    {
        value: 'BUSINESS_DISTRICT',
        text: '商圈'
    },
    {
        value: 'REGION',
        text: '行政区域'
    },
    {
        value: 'OVERSEA',
        text: '海外区域'
    },
    {
        value: 'NONE',
        text: '不限'
    }
]

// 广告投放位置
export const inventoryType = [
    {
        value: 'INVENTORY_FEED',
        text: '今日头条'
    },
    {
        value: 'INVENTORY_VIDEO_FEED',
        text: '西瓜视频'
    },
    {
        value: 'INVENTORY_AWEME_FEED',
        text: '抖音短视频'
    },
    {
        value: 'INVENTORY_TOMATO_NOVEL',
        text: '番茄小说'
    },
    {
        value: 'INVENTORY_UNION_SLOT',
        text: '穿山甲'
    },
    {
        value: 'UNION_BOUTIQUE_GAME',
        text: 'ohayoo精品游戏'
    },
    {
        value: 'INVENTORY_SEARCH',
        text: '搜索广告'
    },
    {
        value: 'INVENTORY_UNIVERSAL',
        text: '通投智选(广告投放)'
    }
]
