<template>
    <a-modal title="" :visible="visible" :footer="null" width="800px" :dialog-style="{ top: '10px' }"
        @cancel="handleCancel">
        <div>
            <a-tabs type="card" v-model="currentKey" :tabBarGutter="15" size="large">
                <a-tab-pane key="detail" tab="详情">
                    <Detail :form="form" v-if="form.adProjectId" />
                </a-tab-pane>
                <a-tab-pane key="log" tab="日志">
                    <a-row type="flex" justify="space-between" style="margin: 0 0 20px;">
                        <a-col>
                            巨量项目: {{ form.adProjectName }}
                        </a-col>
                        <a-col>
                            巨量项目ID: {{ form.adProjectId || '-' }}
                        </a-col>
                    </a-row>
                    <a-table :columns="columns" :data-source="logData" :rowKey="(record, index) => index"
                        :scroll="{ y: 637 }" :pagination='false'>
                        <div slot="area" slot-scope="text, record">
                            {{ record }}
                        </div>
                    </a-table>
                    <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize"
                        :total="pagination.total" @onChange="handlePaginationChange"
                        @onShowSizeChange="handlePaginationChange" />

                </a-tab-pane>
            </a-tabs>
        </div>
    </a-modal>
</template>
<script>
import Detail from './DetailComp.vue'
import { bidType, landingType, marketingGoal, adType, promotionType, district, inventoryType } from './typeList'

export default {
    components: { Detail },
    props: {
        openKey: {
            type: String,
            default: 'detail'
        },
        dataId: {
            type: String,
            default: ''
        },
        adPlatformAccountId: {
            type: String,
            default: ''
        },
        adProjectId: {
            type: String,
            default: ''
        }
    },
    created() {
        this.init()
    },
    data() {
        const columns = [
            {
                align: 'center',
                title: '操作时间',
                dataIndex: 'createTime',
                customRender(text) {
                    return text || ''
                }
            },
            {
                align: 'center',
                title: '操作内容',
                dataIndex: 'contentTitle',
                customRender(text) {
                    return text || ''
                }
            },
            {
                align: 'center',
                title: '操作前后内容',
                dataIndex: 'contentLog',
                customRender(text) {
                    const str = text?.reduce((prev, item) => {
                        prev += `、${item}`;
                        return prev;
                    })
                    return str || ''
                }
            },
            {
                align: 'center',
                title: '操作人',
                dataIndex: 'operator',
                customRender(text) {
                    return text || ''
                }
            },
            {
                align: 'center',
                title: '操作IP',
                dataIndex: 'optIp',
                customRender(text) {
                    return text || ''
                }
            },
        ]
        return {
            visible: true,
            form: {},
            columns,
            logData: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            currentKey: ''
        };
    },
    methods: {
        async init() {
            this.currentKey = this.openKey
            const hide = this.$message.loading('请求中...')
            await this.getDetail()
            await this.getLog()
            hide()
        },
        async getDetail() {
            const { code, message, data } = await this.$api.core.JLAdvertManage.getJLProjectDetail(this.dataId)
            if (code === 200) {
                this.form = data
                this.form.deliverySetting.bidType = bidType.find(v => v.value === data.deliverySetting.bidType).text || data.deliverySetting.bidType
                this.form.landingType = landingType.find(v => v.value === data.landingType)?.text || data.landingType
                this.form.marketingGoal = marketingGoal.find(v => v.value === data.marketingGoal)?.text || data.marketingGoal
                this.form.adType = adType.find(v => v.value === data.adType)?.text || data.adType
                this.form.promotionType = promotionType.find(v => v.value === data.promotionType)?.text || data.promotionType
                this.form.district = district.find(v => v.value === data.district)?.text || data.district
                if (data.deliveryRange.inventoryType.length) {
                    const arr = []
                    inventoryType.forEach((val) => {
                        const index = data.deliveryRange.inventoryType.indexOf(val.value)
                        if (index != -1) arr.push(val.text)
                    })
                    if(arr.length){
                        this.form.deliveryRange.inventoryType = arr.reduce((prev, item) => {
                            prev += `、${item}`;
                            return prev;
                        })
                    }else{
                        this.form.deliveryRange.inventoryType = '-'
                    }
                }
                this.form.cityList = (data.cityList.length && data.cityList.map(val => val.regionName).reduce((prev, item) => {
                    prev += `、${item}`;
                    return prev;
                })) || '-'
            } else {
                this.$message.error(`${message}`)
            }
        },
        async getLog() {
            const params = {
                adPlatformAccountId: this.adPlatformAccountId,
                page: this.pagination.current,
                pageSize: this.pagination.pageSize,
                objectId: [this.adProjectId]
            }
            const { code, message, data } = await this.$api.core.JLAdvertManage.getJLProjectLog(params)
            if (code === 200) {
                this.logData = data.list
                this.pagination.total = data.total || 0
            } else {
                this.$message.error(`${message}`)
            }
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getLog()
        },
        handleCancel(e) {
            this.$emit('closeModal')
        }
    },
};
</script>

<style></style>