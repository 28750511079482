<template>
  <a-card>
    <a-page-header style="padding: 0;margin-top: -18px" title='巨量项目管理' />
    <a-form-model ref="form" :model="form" v-bind='layout'>
      <a-row type="flex" justify="start" style="margin-bottom: -22px">
        <a-col :span="7">
          <a-form-model-item label="查询周期">
            <a-range-picker v-model="pickerTime" value-format="YYYY-MM-DD" :disabled-date="disabledDate"
                            :allow-clear="false" @calendarChange="calendarChange" @openChange="openChange" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="广告主">
            <a-input v-model.trim="form.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="巨量项目">
            <a-input v-model.trim="form.adProjectName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="巨量项目状态">
            <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength="6" v-model="form.adProjectStatusList"
                      placeholder="请选择" show-search allowClear>
              <a-select-option v-for="item in typeList" :key="item.code" :value="item.code">{{ item.msg
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start" style="margin-bottom: -22px">
        <a-col :span="7">
          <a-form-model-item label="巨量项目ID">
            <a-input v-model.trim="form.adProjectId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="隐藏0消费且0转化记录">
            <a-select placeholder="请选择" allowClear v-model="form.hidden">
              <a-select-option key="0">否</a-select-option>
              <a-select-option key="1">是</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="end" style="margin-bottom: -14px">
        <a-button @click="handleReset">重置</a-button>
        <a-button style="margin: 0 5px 0 20px;" type="primary" @click="handleSearch">查询</a-button>
      </a-row>
    </a-form-model>

    <a-table class="Atables" style="margin-top: 20px;" size="small" :loading="tableLoading" :columns="columns"
             :data-source="dataList"
             :rowKey="(record, index) => index" :pagination='false' :scroll="{ x: 2600 }" @change="handleTableChange">
      <div slot="projectTitle">
        <div>巨量项目</div>
        <!--<div>巨量项目ID</div>-->
      </div>
      <div slot="adProjectId" slot-scope="text, record">
        <p v-if="record.adProjectName == '合计'">{{ record.adProjectName }}</p>
        <a @click="handleJump(record)" v-else> {{ record.adProjectName }}</a>
        <!--<div class="gray-text">{{ record.adProjectId }}</div>-->
      </div>
      <div slot="cityStr" slot-scope="text, record">
        <div :title="record.cityStr">{{ text }}</div>
      </div>
      <div slot="budget" slot-scope="text, record">{{ handleBudget(record) }}</div>
      <div slot="operation" slot-scope="text, record, index" style="display: flex;align-items: center">
        <div v-show="index !== 0">
          <a-button type="link"
                    style="font-size: 12px"
                    @click="handleOpen('detail', record.id, record.adPlatformAccountId, record.adProjectId)">详情
          </a-button>
          <a-button type="link" @click="handleJump(record)" style="font-size: 12px;padding: 0">广告</a-button>
        </div>
        <a-popover title="更多操作" trigger="focus">
          <a-button type="link" v-show="index !== 0" style="font-size: 12px">···</a-button>
          <a-button slot="content" type="link"
                    @click="handleOpen('log', record.id, record.adPlatformAccountId, record.adProjectId)">操作日志
          </a-button>
        </a-popover>
      </div>
    </a-table>
    <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
                     @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />

    <ModalComp v-if="showModal" :openKey="openKey" @closeModal="handleCloseModal" :dataId="dataId"
               :adPlatformAccountId="adPlatformAccountId" :adProjectId="adProjectId" />
  </a-card>
</template>

<script>
import moment from 'moment';
import ModalComp from './ModalComp.vue';
import { isEmpty, pick } from 'lodash-es';

export default {
  components: { ModalComp },
  data() {
    const columns = [
      {
        align: 'center',
        fixed: 'left',
        title: '巨量项目平台开关',
        width: 40,
        dataIndex: 'adOptStatus',
        customRender(text) {
          return !text ? '' : text === 'ENABLE' ? '启用' : '禁用';
        },
      },
      {
        align: 'center',
        fixed: 'left',
        title: '巨量项目状态',
        width: 40,
        dataIndex: 'adProjectStatusDesc',
        customRender(text) {
          return text || '';
        },
      },
      {
        fixed: 'left',
        width: 150,
        align: 'center',
        key: 'adProjectId',
        dataIndex: 'adProjectId',
        slots: { title: 'projectTitle' },
        scopedSlots: { customRender: 'adProjectId' },
      },
      // {
      //   align: 'center',
      //   fixed: 'left',
      //   title: '地域',
      //   dataIndex: 'cityStr',
      //   width: 150,
      //   ellipsis: true,
      //   scopedSlots: { customRender: 'cityStr' },
      // },
      {
        align: 'center',
        fixed: 'left',
        title: '广告主',
        width: 140,
        dataIndex: 'advertiserName',
        customRender(text) {
          return text || '';
        },
      },
      {
        title: '操作',
        fixed: 'left',
        width: 160,
        align: 'center',
        scopedSlots: { customRender: 'operation' },
      },
      {
        align: 'center',
        title: '巨量项目预算',
        dataIndex: 'budget',
        width: 60,
        scopedSlots: { customRender: 'budget' },
      },
      {
        align: 'center',
        title: '出价(元)',
        dataIndex: 'cpaBid',
        width: 60,
        customRender(text) {
          return text || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '消费(元)',
        key: 'statCost',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.statCost || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '目标转化数',
        key: 'convertCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.convertCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '转化单价(元)',
        key: 'convertCost',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.convertCost || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示数',
        key: 'showCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.showCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击数',
        key: 'clickCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.clickCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信数',
        key: 'messageAction',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.messageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资数',
        key: 'clueMessageCount',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.clueMessageCount || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资单价（元）',
        key: 'unitPriceMessageAction',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.unitPriceMessageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '表单提交数',
        key: 'form',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.form || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '千次展示单价（元）',
        key: 'unitPricePerThousandShow',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.unitPricePerThousandShow || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示转化率(%)',
        key: 'showConvertRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.showConvertRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击率(%)',
        key: 'clickRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.clickRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击转化率(%)',
        key: 'clickConvertRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.clickConvertRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击单价（元）',
        key: 'clickUnitPrice',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.clickUnitPrice || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资率(%)',
        key: 'clueMessageCountRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.clueMessageCountRate || '-';
        },
      },
      {
        align: 'center',
        title: '广告数',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text?.metric?.adPromotionCount || '-';
        },
      },
      {
        align: 'center',
        title: '本日有消费广告数',
        customRender(text) {
          return text?.metric?.todayCostAdPromotionCount || '-';
        },
      },
    ];
    return {
      typeList: [],
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      form: {
        advertiserName: undefined,
        adProjectName: undefined,
        adProjectStatusList: [],
        hidden: '0',
        adProjectId: undefined,
      },
      pickerTime: [moment(), moment()],
      selectStartDay: '',
      tableLoading: false,
      columns,
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
      },
      totalData: {},
      dataList: [],
      sortList: [],
      showModal: false,
      openKey: 'detail',
      dataId: '',
      adPlatformAccountId: '',
      adProjectId: '',
    };
  },
  created() {
    if (this.$route.params.id || this.$route.query.id || this.$route.query.DATA_TIME) {
      this.form.adProjectId = this.$route.params.id || this.$route.query.id;
      this.pickerTime = this.$route.query.DATA_TIME;
    } else {
      try {
        const params = JSON.parse(sessionStorage.getItem('__advertising_project_manage__') || '{}');
        if (!isEmpty(params)) {
          this.form = { ...this.form, ...pick(params, ['adProjectName', 'adProjectStatusList', 'advertiserName', 'adProjectId', 'hidden']) };
          if (params.startTime) this.pickerTime = [moment(params.startTime), moment(params.endTime)];
          // this.sortList = params.sortList;
          this.pagination.current = params.page;
          this.pagination.pageSize = params.size;
        }
      } finally {
      }
    }
    this.$nextTick(this.init);
  },
  methods: {
    async init() {
      await this.getProjectTypeList();
      this.getDataList();
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    async getDataList() {
      const startTime = `${ moment(this.pickerTime[0]).format('YYYY-MM-DD') } 00:00:00`;
      const endTime = `${ moment(this.pickerTime[1]).format('YYYY-MM-DD') } 23:59:59`;
      const { adProjectName, adProjectStatusList, advertiserName, adProjectId, hidden } = this.form;
      const params = {
        startTime,
        endTime,
        adProjectName,
        adProjectStatusList,
        advertiserName,
        adProjectId,
        hidden,
        sortList: this.sortList,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sessionStorage.setItem('__advertising_project_manage__', JSON.stringify(params));
      this.tableLoading = true;
      await this.getDataTotal(params);
      await this.getDataRest(params);
      this.dataList.unshift(this.totalData);
      this.tableLoading = false;
    },
    async getProjectTypeList() {
      const callBackFn = (data) => {
        this.typeList = data;
        if (data.length) {
          // 默认选中
          const params = JSON.parse(sessionStorage.getItem('__advertising_project_manage__') || '{}');
          if (!isEmpty(params?.adProjectStatusList) && !this.$route.params.id) {
            this.form.adProjectStatusList = params.adProjectStatusList;
          } else {
            const unchooseList = [
              'PROJECT_STATUS_DELETE', 'PROJECT_STATUS_ALL', 'PROJECT_STATUS_NOT_DELETE',
            ];
            this.form.adProjectStatusList = this.typeList.filter(v => (unchooseList.indexOf(v.code) === -1)).map((value) => (value.code));
          }
        }
      };
      await this.handleRequest('getJLProjectTypeList', (data) => callBackFn(data));
    },
    // 列表合计
    async getDataTotal(params) {
      this.totalData = {};
      const param = JSON.parse(JSON.stringify(params));
      delete param.page;
      delete param.size;
      delete param.sortList;
      await this.handleRequest('getJLProjectManageTotal', (data) => {
        this.totalData = {
          adProjectName: '合计',
          metric: data,
        };
      }, param);
    },
    // 列表剩余
    async getDataRest(params) {
      const callBackFn = (data) => {
        this.dataList = data.list.map(value => {
          // 城市列表
          let cityArr = value.cityList?.length && value.cityList.map((val) => {
            return val.regionName;
          }) || [];
          let cityStr = '不限';
          if (cityArr.length) {
            cityStr = cityArr.reduce((prev, item) => {
              prev += `、${ item }`;
              return prev;
            });
          }
          value.cityStr = cityStr;
          return value;
        });
        this.pagination.total = data.total || 0;
      };
      await this.handleRequest('getJLProjectManageList', (data) => callBackFn(data), params);
    },
    async handleRequest(API, callback, params = null) {
      const { code, message, data } = await this.$api.core.JLAdvertManage[API](params);
      if (code === 200) {
        callback(data);
      } else {
        this.$message.error(`${ message }`);
      }
    },
    disabledDate(current) {
      return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days');
    },
    calendarChange(dates, dateStrings) {
      this.selectStartDay = dates[0];
    },
    openChange() {
      this.selectStartDay = '';
    },
    handleTableChange(pagination, filters, sorter) {
      const sort = sorter.order ? [{
        fieldName: sorter.columnKey,
        orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
      }] : [];
      this.sortList = sort;
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleOpen(key, dataId, adPlatformAccountId, adProjectId) {
      this.showModal = true;
      this.dataId = dataId;
      this.adPlatformAccountId = adPlatformAccountId;
      this.adProjectId = adProjectId;
      this.openKey = key;
    },
    handleCloseModal() {
      this.showModal = false;
      this.dataId = '';
      this.adPlatformAccountId = '';
      this.adProjectId = '';
    },
    handleReset() {
      this.form = this.$options.data().form;
      const unchooseList = [
        'PROJECT_STATUS_DELETE', 'PROJECT_STATUS_ALL', 'PROJECT_STATUS_NOT_DELETE',
      ];
      this.form.adProjectStatusList = this.typeList.filter(v => (unchooseList.indexOf(v.code) === -1)).map((value) => (value.code));
      this.pickerTime = [moment(), moment()];
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.getDataList();
    },
    handleJump(row) {
      this.$router.push({ name: 'advertisingManage', params: { adProjectId: row.adProjectId } });
    },
    handleBudget(data) {
      let text = `${ data.budget }`;
      if (!data.budget) text = '-';
      if (data.adProjectName === '合计') text = '-';
      return text;
    },
  },
};
</script>

<style scoped lang="less">
.gray-text {
  color: #bbbbbb
}

.label-box {
  width: 90%; // 建议80%，太长就会超出内容
  display: inline-block;
  height: auto;
  white-space: break-spaces;
  //line-height: 18px;
  text-align: left;
  vertical-align: bottom; // 这是为了让整体的字往下移动一点
}


.Atables /deep/ .ant-table {
  font-size: 10px;
}

.Atables /deep/ .ant-table th,
.Atables /deep/ .ant-table td {
  font-size: 10px;
}
</style>
